<template>
  <div>
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="tableFrom"
        ref="searchForm"
        label-width="85px"
      >
        <div style="width: 100%">
          <el-form-item label="订单状态：" prop="status">
            <el-radio-group v-model="tableFrom.status" type="button">
              <div style="display: flex">
                <el-radio-button
                  :label="i.value"
                  v-for="i in statusList"
                  :key="i.label"
                  >{{ i.label }}{{ i.num }}</el-radio-button
                >
                <!-- <el-radio-button label="0">待支付</el-radio-button>
                <el-radio-button label="10">待发货</el-radio-button>
                <el-radio-button label="11">部分发货</el-radio-button>
                <el-radio-button label="20">待收货</el-radio-button>
                <el-radio-button label="30">待评价</el-radio-button>
                <el-radio-button label="40">已完成</el-radio-button>
                <el-radio-button label="90">已关闭</el-radio-button> -->
              </div>
            </el-radio-group>
          </el-form-item>
        </div>

        <el-form-item label="订单编号：" prop="orderCode">
          <el-input
            v-model="tableFrom.orderCode"
            placeholder="请输入订单编号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="售后单号：" prop="refundCode">
          <el-input
            v-model="tableFrom.refundCode"
            placeholder="请输入售后单号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="下单人：" prop="userName">
          <el-input
            v-model="tableFrom.userName"
            placeholder="请输入下单人"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="收货人：" prop="shippingName">
          <el-input
            v-model="tableFrom.shippingName"
            placeholder="请输入收货人："
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="推广员：" prop="extensionName">
          <el-input v-model="tableFrom.extensionName" placeholder="请输入推广员" class="input-with-select selWidth"
            clearable />
        </el-form-item>
        <el-form-item label="电话：" prop="mobile">
          <el-input
            v-model="tableFrom.mobile"
            placeholder="请输入电话"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品名称：" prop="goodsName">
          <el-input
            v-model="tableFrom.goodsName"
            placeholder="请输入商品名称："
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker
            class="input-with-select selWidth"
            v-model="tableFrom.time"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="small"
            type="daterange"
            placement="bottom-end"
            style="width: 280px"
            :picker-options="pickerOptions"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="getList()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
          <el-button size="small" @click="exportFunList()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card class="box-card">
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        :default-expand-all="true"
        size="small"
        row-key="id"
      >
        <el-table-column type="expand">
          <template slot-scope="orderItems">
            <div class="orderItems">
              <div
                v-for="(item, index) in orderItems.row.orderItems"
                class="item"
                :key="index"
              >
                <img class="item_img" :src="item.goodsSkuImg" alt="" />
                <div>
                  <div class="item_name">{{ item.goodsName }}</div>
                  <div class="item_price">售价：￥{{ item.salePrice }}</div>
                  <div class="item_num supplier">
                    供应商：{{ item.supplierName }}
                  </div>
                  <div class="item_num">规格：{{ item.goodsSkuName }}</div>
                  <div class="item_num">数量：x{{ item.quantity }}</div>
                </div>
                <el-button
                  class="afterBtn"
                  @click="afterDetails(item)"
                  icon="el-icon-info"
                  v-if="item.refundStatus != 0 && item.refundStatus != 4"
                  type="text"
                  size="small"
                  >售后详情</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          align="left"
          label="订单编号"
          prop="code"
          width="200px"
        >
        </el-table-column>
        <el-table-column label="用户昵称" prop="nickname" align="center" />
        <el-table-column align="center" label="推广员" prop="extensionName" min-width="150" />
        <el-table-column :align="'center'" label="订单状态">
          <template slot-scope="scope">
            {{ stateData[String(scope.row.status)] }}
          </template>
        </el-table-column>
        <el-table-column
          :align="'center'"
          label="下单时间"
          prop="orderTime"
          width="170px"
        >
        </el-table-column>
        <el-table-column
          :align="'center'"
          label="主单编号"
          prop="orderCode"
          width="170px"
        >
        </el-table-column>
        <el-table-column label="买家留言" prop="remark" min-width="200" align="center"  />
        <el-table-column label="卖家备注" prop="reply" min-width="200" align="center"  />
        <el-table-column label="支付类型" >
          <template slot-scope="scope">
            <span>{{payTypes[scope.row.payType]}}</span>
          </template>
        </el-table-column>
        <el-table-column :align="'center'" label="订单金额" prop="orderAmount">
        </el-table-column>
        <el-table-column
          label="实付金额(元）"
          prop="payAmount"
          min-width="100"
        />
        </el-table-column>
        <el-table-column
          label="积分抵扣(元)"
          prop="scoreAmount"
          min-width="100"
        />
        <el-table-column label="使用积分" prop="score"/>

        <el-table-column
          label="收货人姓名"
          prop="shippingName"
          :align="'center'"
          min-width="120"
        />
        <el-table-column 
          label="收货人电话"
          prop="shippingMobile"
          :align="'center'"
          min-width="150"
        />
        <el-table-column label="收货人地址" min-width="250" :align="'center'">
          <template slot-scope="scope">
            <span>{{
              scope.row.shippingProvince +
              scope.row.shippingCity +
              scope.row.shippingDistrict +
              scope.row.shippingAddress
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column :align="'center'" label="下单人" prop="nickname">
        </el-table-column>
        <el-table-column :align="'center'" label="商品金额" prop="goodsAmount">
        </el-table-column> -->
        <el-table-column
          label="操作"
          :align="'center'" 
          width="250px"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status === 10 || scope.row.status === 11"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="onShipments(scope.row, 1)"
              >发货</el-button
            >
            <el-button
              type="text"
              size="mini"
              icon="el-icon-info"
              @click="onShipments(scope.row, 2)"
              >查看详情</el-button
            >
            <el-button
              type="text"
              size="mini"
              icon="el-icon-view"
              @click="evaluate(scope.row.id)"
              >查看评价</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <!-- v-loading="loading" -->
    <!-- 订单发货 -->
    <DeliverGoodsVue
      ref="delive"
      :loading="loading"
      :tableData="tableData"
      :orderDetails="orderDetails"
      :expressLists="expressLists"
      @updateData="getList()"
    />
    <el-drawer
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
      size="55%"
      :with-header="false"
    >
      <div v-loading="loading">
        <el-card shadow="never" style="border: none">
          <div class="headers">
            <img
              style="width: 60px; height: 60px; margin-right: 15px"
              :src="require('@/assets/images/order_icon.png')"
            />
            <div>
              <div class="title">普通订单</div>
              <div class="orderno">订单编号：{{ orderDetails.code }}</div>
            </div>
          </div>
        </el-card>
        <el-card shadow="never" style="border: none">
          <el-descriptions
            title=""
            :column="4"
            direction="vertical"
            :colon="false"
          >
            <el-descriptions-item label="订单状态">{{
              stateData[String(orderDetails.status)]
            }}</el-descriptions-item>
            <el-descriptions-item label="实际支付">{{
              orderDetails.payAmount
            }}</el-descriptions-item>
            <el-descriptions-item label="支付方式">
              {{ payTypes[orderDetails.payType] }}
              <!-- {{
              orderDetails.payType == 1
                ? "微信支付"
                : orderDetails.payType == 2
                ? "支付宝"
                : orderDetails.payType == 3
                ? "积分支付"
                : orderDetails.payType == 5
                ? "混合支付"
                : "免支付"
            }}-->
            </el-descriptions-item>
            <el-descriptions-item label="支付时间">{{
              orderDetails.payAt
            }}</el-descriptions-item>
          </el-descriptions>
        </el-card>
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane label="订单信息" name="first">
            <el-card
              shadow="never"
              style="border-bottom: 1px soild #ccc; border-top: none"
            >
              <el-descriptions title="用户信息" :column="2">
                <el-descriptions-item label="用户昵称">{{
                  orderDetails.nickname
                }}</el-descriptions-item>
                <el-descriptions-item label="用户手机号 ">{{
                  orderDetails.phone
                }}</el-descriptions-item>
              </el-descriptions>
            </el-card>
            <el-card
              shadow="never"
              style="border-bottom: 1px soild #ccc; border-top: none"
            >
              <el-descriptions title="收货信息" :column="2">
                <el-descriptions-item label="收货人">{{
                  orderDetails.shippingName
                }}</el-descriptions-item>
                <el-descriptions-item label="收货电话 ">{{
                  orderDetails.shippingMobile
                }}</el-descriptions-item>
                <el-descriptions-item label="收货地址"
                  >{{
                    orderDetails.shippingProvince +
                    orderDetails.shippingCity +
                    orderDetails.shippingDistrict +
                    "  "
                  }}{{ orderDetails.shippingAddress }}</el-descriptions-item
                >
              </el-descriptions>
            </el-card>
            <el-card
              style="
                margin-top: 30px;
                border-bottom: 1px soild #ccc;
                border-top: none;
              "
              shadow="never"
            >
              <el-descriptions title="订单信息" :column="3">
                <el-descriptions-item label="创建时间">{{
                  orderDetails.orderTime
                }}</el-descriptions-item>
                <el-descriptions-item label="商品总数">{{
                  getCountAll()
                }}</el-descriptions-item>
                <el-descriptions-item label="实际支付">{{
                  orderDetails.payAmount
                }}</el-descriptions-item>
                <!-- <el-descriptions-item label="优惠金额">{{
                orderDetails.couponAmount
              }}</el-descriptions-item> -->
                <el-descriptions-item label="订单总价">{{
                  orderDetails.goodsAmount
                }}</el-descriptions-item>
                <el-descriptions-item label="支付运费">{{
                  orderDetails.freight
                }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions
                :column="1"
                :colon="false"
                v-if="stateData[String(orderDetails.status)] == '已关闭'"
              >
                <el-descriptions-item label="关闭原因:">{{
                  orderDetails.closedReason
                }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions
                :column="1"
                :colon="false"
                
              >
                <el-descriptions-item label="买家留言:">
                    {{orderDetails.remark}}
                </el-descriptions-item>
              </el-descriptions>
              <el-descriptions
                :column="1"
                :colon="false"
                
              >
                <el-descriptions-item label="卖家备注:">
                    {{orderDetails.reply}}
                </el-descriptions-item>
              </el-descriptions>
            </el-card>
            <!-- <el-card
              style="
                margin-top: 30px;
                border-bottom: 1px soild #ccc;
                border-top: none;
              "
              shadow="never"
            >
              <el-descriptions title="卖家留言" :column="1" :colon="false">
                <el-descriptions-item>{{
                  orderDetails.remark || "暂无留言"
                }}</el-descriptions-item>
              </el-descriptions>
            </el-card> -->
            <!-- <el-card style="margin-top:30px;border:none" shadow="never">
            <el-descriptions title="商家备注" :column="1" :colon="false">
              <el-descriptions-item>kooriookami</el-descriptions-item>
            </el-descriptions>
          </el-card> -->
          </el-tab-pane>

          <el-tab-pane label="商品信息" name="second">
            <el-card style="border: none">
              <el-table
                v-loading="listLoading"
                :data="orderDetails.orderItems"
                size="small"
                row-key="id"
                :border="false"
                :default-expand-all="false"
                :tree-props="{
                  children: 'children',
                  hasChildren: 'hasChildren',
                }"
              >
                <el-table-column type="index" width="100" label="序号" />
                <el-table-column label="商品图" min-width="170">
                  <template slot-scope="scope">
                    <div class="demo-image__preview">
                      <el-image
                        style="width: 36px; height: 36px"
                        :src="scope.row.goodsImg"
                        :preview-src-list="[scope.row.goodsImg]"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品名称"
                  prop="goodsName"
                  min-width="170"
                />
                <el-table-column label="商品SKU图片" min-width="170">
                  <template slot-scope="scope">
                    <div class="demo-image__preview">
                      <el-image
                        style="width: 36px; height: 36px"
                        :src="scope.row.goodsSkuImg"
                        :preview-src-list="[scope.row.goodsSkuImg]"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品SKU编码"
                  prop="outerSkuCode"
                  min-width="170"
                />
                <el-table-column
                  label="商品SKU名称"
                  prop="goodsSkuName"
                  min-width="170"
                />
                <el-table-column
                  label="原价"
                  prop="originalPrice"
                  min-width="170"
                />
                <el-table-column
                  label="售价"
                  prop="salePrice"
                  min-width="170"
                />
                <el-table-column
                  label="商品数量"
                  prop="quantity"
                  min-width="170"
                />
                <el-table-column label="应收" prop="salePrice" min-width="170">
                  <template slot-scope="scope">
                    {{
                      Number(scope.row.salePrice * scope.row.quantity).toFixed(
                        2
                      )
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="扣减积分"
                  prop="scorePrice"
                  min-width="170"
                />
                <el-table-column
                  label="优惠金额"
                  prop="couponPrice"
                  min-width="170"
                />
                <el-table-column
                  label="现金支付"
                  prop="buyPrice"
                  min-width="170"
                />
                <el-table-column label="发货状态" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.shippingStatus === 0 ? "未发货" : "已发货" }}
                  </template>
                </el-table-column>
                <el-table-column label="售后状态" min-width="130">
                  <template slot-scope="scope">
                    <div>
                      {{
                        scope.row.refundStatus == 0
                          ? "未申请 "
                          : scope.row.refundStatus == 1
                          ? "进行中 "
                          : scope.row.refundStatus == 2
                          ? "部分完成"
                          : scope.row.refundStatus == 3
                          ? "申请完成"
                          : scope.row.status == 4
                          ? "申请失败"
                          : "-"
                      }}
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="操作"
                  prop="quantity"
                  min-width="170"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      v-if="
                        scope.row.refundStatus != 0 &&
                        scope.row.refundStatus != 4
                      "
                      size="small"
                      icon="el-icon-info"
                      @click="afterDetails(scope.row)"
                      >售后详情</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-tab-pane>

          <el-tab-pane label="物流信息" name="wl">
            <el-card style="border-top: none">
              <el-radio-group v-model="tabPosition" style="margin-bottom: 30px">
                <el-radio-button
                  :label="index"
                  v-for="(item, index) in orderDetails.orderShippings"
                  :key="index"
                  >{{ "包裹" + (index + 1) }}</el-radio-button
                >
              </el-radio-group>
              <el-descriptions
                :column="1"
                v-if="orderDetails.orderShippings.length > 0"
              >
              
              <el-descriptions-item label="商品名称">
        <div v-for="(item, index) in orderDetails.orderShippings[tabPosition].shippingItems" :key="index" style="margin-bottom: 10px;">
          <el-tag type="info">{{item.goodsName}}</el-tag>
        </div>
      </el-descriptions-item>

                <el-descriptions-item label="物流公司">{{
                  orderDetails.orderShippings[tabPosition].expressCompany
                }}</el-descriptions-item>
                <el-descriptions-item label="物流单号">{{
                  orderDetails.orderShippings[tabPosition].expressNo
                }}
     
              </el-descriptions-item>
                <el-descriptions-item label="发货时间">{{
                  orderDetails.orderShippings[tabPosition].shippingTime
                }}</el-descriptions-item>
                <el-descriptions-item label="收货地址"
                  >{{
                    orderDetails.shippingProvince +
                    orderDetails.shippingCity +
                    orderDetails.shippingDistrict +
                    "  "
                  }}{{ orderDetails.shippingAddress }}</el-descriptions-item
                >
              </el-descriptions>

              <el-button type="primary" :loading="expressFormLoading" @click="upExpressNoBefor(orderDetails.orderShippings[tabPosition])" style="margin-bottom: 10px;">修改快递单号</el-button>
              <el-steps
                direction="vertical"
                :active="1"
                :space="75"
                v-if="orderDetails.orderShippings.length > 0"
              >
                <el-step
                  v-for="(i, index) in orderDetails.orderShippings[tabPosition]
                    .expressLists"
                  :key="index"
                  :title="i.AcceptStation"
                  :description="i.AcceptTime"
                  :icon="true ? 'el-icon-document-checked' : 'el-icon-success'"
                ></el-step>
              </el-steps>
              <div v-else style="text-align: center; font-size: 14px">
                无需物流
              </div>
            </el-card>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
    <ReturnOrderDetails
      v-if="afterDetailsShow"
      @closeDrawer="closeDrawer"
      :drawer="afterDetailsShow"
      :orderId="orderId"
      ref="returnOrderDetails"
    />
    <el-dialog
        title="修改快递单号"
        :visible.sync="dialogVisibleExpressNo"
        width="500px"
      >
        <el-form :model="dialogVisibleExpressForm" ref="dialogVisibleExpressForm" label-width="100px" class="demo-dynamic">
          <el-form-item
                prop="expressCompanyCode"
                label="快递公司"
                :rules="[
                  { required: true, message: '请选择快递公司', trigger: 'blur' },
                ]"
          >
                <el-select
                  v-model="dialogVisibleExpressForm.expressCompanyCode"
                  filterable
                  placeholder="请选择物流公司"
                >
                  <el-option
                    v-for="item in expressLists"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  >
                  </el-option>
                </el-select>
          </el-form-item>
          <el-form-item
                prop="expressNo"
                label="快递单号"
                :rules="[
                  { required: true, message: '请输入快递单号', trigger: 'blur' },
                ]"
          >
              <el-input placeholder="请输入快递单号" v-model="dialogVisibleExpressForm.expressNo"></el-input>
          </el-form-item>
      </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleExpressNo = false">取 消</el-button>
          <el-button type="primary" @click="upExpressNo">确 定</el-button>
        </span>
</el-dialog>
    <!--查看评价-->
    <el-dialog
      title="查看评价"
      :visible.sync="dialogEvaluateVisible"
      width="1000px"
    >
      <EvaluateTable v-if="dialogEvaluateVisible" :type="2" :orderId="orderId" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEvaluateVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogEvaluateVisible = false"
          >确 定</el-button 
        >
      </span>
    </el-dialog>
  </div> 
</template>

<script>
import { state, payTypes } from "./orders";
import ReturnOrderDetails from "../order/components/returnOrderDetails.vue";
import EvaluateTable from "../storeOrder/evaluateTable.vue";
import {
  list,
  view,
  expressList,
  expressLogistics,
  ordersNum,
  shippingUp,
} from "@/api/supplierGoods/order";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import DeliverGoodsVue from "./components/deliverGoods.vue";
import timeOptions from "@/assets/utils/timeOptions";
import { exportExcel } from "@/assets/utils/exportExcel";
export default {
  name: "ProductClassify",
  components: {
    FormImgUpload,
    DeliverGoodsVue,
    ReturnOrderDetails,
    EvaluateTable
  },
  data() {
    return {
      orderId: "",
      dialogEvaluateVisible: false, //弹框评论
      afterDetailsShow: false,
      loading: false,
      pickerOptions: timeOptions,
      tabPosition: 0,
      orderDetails: {
        orderShippings: [],
      },
      payTypes,
      stateData: state, //状态对象数据
      activeName: "first", //tab切换默认
      drawer: false,
      type: 1,
      moren: require("@/assets/images/bjt.png"),
      isChecked: false,
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 10,
        status: "",
        orderCode: "",
        userName: "",
        shippingName: "",
        mobile: "",
        goodsName: "",
        time: [],
        extensionName: "",
      },
      title: "新增分类",
      form: {},
      value: [],
      expressLists: [],
      statusList: [
        {
          label: "全部",
          num: 0,
          value: "",
        },
        {
          label: "待支付",
          num: 0,
          value: "0",
        },
        {
          label: "待发货",
          num: 0,
          value: "10",
        },
        {
          label: "部分发货",
          num: 0,
          value: "11",
        },
        {
          label: "待收货",
          num: 0,
          value: "20",
        },
        {
          label: "已完成",
          num: 0,
          value: "40",
        },
        {
          label: "已关闭",
          num: 0,
          value: "90",
        },
        {
          label: "已取消",
          num: 0,
          value: "100",
        },
      ],
      dialogVisibleExpressNo: false, //修改快递单号弹框
      dialogVisibleExpressForm: {}, //修改快递单号表单
      expressFormLoading: false,
    };
  },
  watch: {
    "tableFrom.status": {
      handler: function () {
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
    this.getExpressLists();
    this.ordersNum();
  },
  methods: {
    evaluate(id) {
      this.dialogEvaluateVisible = true
      this.orderId = id;
    },
    upExpressNoBefor(obj) {
      console.log("123", obj);
      obj.orderId = obj.id;
      this.dialogVisibleExpressForm = obj;
      this.dialogVisibleExpressNo = true;
    },
    upExpressNo() {
      this.$refs["dialogVisibleExpressForm"].validate((valid) => {
        if (valid) {
          this.expressFormLoading = true;
          shippingUp(this.dialogVisibleExpressForm)
            .then((res) => {
              this.expressFormLoading = false;
              this.dialogVisibleExpressNo = false;
              this.drawer = false;
            })
            .catch((res) => {
              this.expressFormLoading = false;orderId
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    exportFunList() {
      exportExcel("/order/web/orders/subExport", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "订单列表",
      });
    },
    afterDetails(row) {
      this.orderId = row.refundId;
      this.afterDetailsShow = true;
    },
    closeDrawer() {
      this.afterDetailsShow = false;
    },
    async ordersNum() {
      const res = await ordersNum();
      this.statusList[0].num = res.data["count"];
      this.statusList[1].num = res.data["count0"];
      this.statusList[2].num = res.data["count10"];
      this.statusList[3].num = res.data["count11"];
      this.statusList[4].num = res.data["count20"];
      // this.statusList[5].num = res.data["count30"];
      this.statusList[5].num = res.data["count40"];
      this.statusList[6].num = res.data["count90"];
      this.statusList[7].num = res.data["count100"];
    },
    // 获取物流列表
    async getExpressLists() {
      console.log("1231231---");
      const { data } = await expressList();
      this.expressLists = data;
      console.log("expressLists", this.expressLists);
    },
    async expressLogistics() {
      this.orderDetails.orderShippings.map(async (item) => {
        if (item.id) {
          let { data } = await expressLogistics({ type: 1, id: item.id });
          item.expressLists =
            data.traces !== "暂无轨迹信息" ? JSON.parse(data.traces) : [];
        }
      });
    },
    handleClose() {
      this.activeName = "first";
      if (this.type !== 1) {
        this.drawer = false;
      } else {
        this.$refs.form.resetFields();
        this.dialogVisible = false;
      }
    },
    getList() {
      if (this.tableFrom.time.length > 0) {
        this.tableFrom.startTime = this.tableFrom.time[0] + " " + "00:00:00";
        this.tableFrom.endTime = this.tableFrom.time[1] + " " + "23:59:59";
      } else {
        this.tableFrom.startTime = "";
        this.tableFrom.endTime = "";
      }
      this.listLoading = true;
      const form = { ...this.tableFrom };
      delete form.time;
      console.log(form, "this.tableFromthis.tableFrom");
      list(form)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 编辑
    onShipments(row, type) {
      this.loading = true;
      this.type = type;
      this.title = type === 1 ? "订单发货" : "订单信息";
      this.getOrderDetails(row);
      if (type === 1) {
        this.$refs.delive.open();
      } else if (type === 2) {
        this.drawer = true;
      }
    },
    getOrderDetails(row) {
      view({ orderId: row.id }).then((res) => {
        res.data.noList = [];
        res.data.yesList = [];
        res.data.orderItems.forEach((item) => {
          if (item.quantity != item.shippingQuantity) {
            let q = item.quantity - item.shippingQuantity;
            res.data.noList.push({ ...item, quantity: q, quantityCopy: q });
          }

          if (item.shippingQuantity > 0) {
            res.data.yesList.push({ ...item });
          }
        });
        // res.data.noList = res.data.orderItems.filter(
        //   (item) => {
        //     item.quantityCopy = item.quantity
        //     return item.quantity != item.shippingQuantity
        //   }
        // );
        // res.data.yesList = res.data.orderItems.filter(
        //   (item) => item.shippingStatus === 1
        // );
        this.orderDetails = res.data;

        if (this.type == 2 && res.data.orderShippings.length > 0) {
          this.expressLogistics();
        }

        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    },
    getCountAll() {
      if (this.orderDetails.orderItems) {
        let num = 0;
        this.orderDetails.orderItems.map((val) => {
          num += val.quantity;
        });
        return num;
      }
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.tableFrom.pageNum = 1;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.headers {
  display: flex;
  align-items: center;
  .title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
  }

  .orderno {
    font-weight: 500;
    font-size: 14px;
    color: #606266;
  }
}

.item {
  width: 100%;
  display: flex;
  padding-bottom: 5px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 10px;
  position: relative;
  // align-items: center;
}
.afterBtn {
  position: absolute;
  top: 0;
  right: 50px;
}
.item_img {
  width: 86rpx;
  height: 86px;
  margin-right: 10px;
  border-radius: 5px;
}
.item_name {
  font-weight: 500;
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
}
.supplier {
  font-weight: 600;
}
.item_price {
  font-weight: 500;
  font-size: 12px;
  color: #e93323;
  margin-bottom: 5px;
}
.item_num {
  margin-bottom: 5px;
  font-size: 12px;
}
/deep/.el-descriptions-item__label:not(.is-bordered-label) {
  width: 120px;
  margin-right: 10px;
}
</style>
