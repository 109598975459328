<template>
  <el-drawer
    title="订单发货"
    :visible.sync="dialogVisible"
    size="55%"
    :before-close="handleClose"
  >
    <el-card shadow="never" style="border-bottom:none" v-loading="loading">
      <el-descriptions title="" :column="2">
        <el-descriptions-item label="订单编号" labelStyle="color:#333">{{
          orderDetails.code
        }}</el-descriptions-item>
        <el-descriptions-item label="下单时间" labelStyle="color:#333">{{
          orderDetails.orderTime
        }}</el-descriptions-item>
        <el-descriptions-item label="收件人" labelStyle="color:#333">{{
          orderDetails.shippingName
        }}</el-descriptions-item>
        <el-descriptions-item label="收件人电话" labelStyle="color:#333">{{
          orderDetails.shippingMobile
        }}</el-descriptions-item>
        <el-descriptions-item label="收件人地址" labelStyle="color:#333"
          >{{
            orderDetails.shippingProvince +
              orderDetails.shippingCity +
              orderDetails.shippingDistrict +
              "  "
          }}{{ orderDetails.shippingAddress }}</el-descriptions-item
        >
      </el-descriptions>
      <el-switch v-model="isShow" active-text="" inactive-text="拆包发货">
      </el-switch>
      <el-tabs v-model="activeName">
        <el-tab-pane label="未发货" name="first">
          <el-table
            :data="orderDetails.noList"
            size="small"
            row-key="id"
            :default-expand-all="false"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              :align="'center'"
              type="selection"
              width="55"
              :selectable="selectable"
              v-if="isShow"
            >
            </el-table-column>
            <el-table-column label="商品名称" prop="name">
              <template slot-scope="scope">
                <div
                  class="demo-image__preview"
                  style="display:flex;align-items:center"
                >
                  <el-image
                    style="width: 36px; height: 36px;margin-right:5px"
                    :src="scope.row.goodsSkuImg ? scope.row.goodsSkuImg : moren"
                    :preview-src-list="[
                      scope.row.goodsSkuImg ? scope.row.goodsSkuImg : moren,
                    ]"
                  />
                  <div>
                    <div>{{ scope.row.goodsName }}</div>
                    <div>规格：{{ scope.row.goodsSkuName }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="quantity">
            <template slot-scope="scope">
              <div v-if="!isShow">{{ scope.row.quantity }}</div>
              <el-input-number v-model="scope.row.quantityCopy" v-else :min="1" :max="scope.row.quantity" label=""></el-input-number>
            </template>  
            </el-table-column>

            <el-table-column label="售后状态" >
            <template slot-scope="scope">
              {{scope.row.refundStatus == 0 ?'未申请':scope.row.refundStatus == 1 ?'进行中':scope.row.refundStatus ==2  ?'部分完成':scope.row.refundStatus ==  3 ?'申请完成':scope.row.refundStatus ==  4 ? '申请失败 ':''}}
            </template>  
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已发货" name="two">
          <el-table
            :data="orderDetails.yesList"
            size="small"
            row-key="id"
            :default-expand-all="false"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column label="商品名称" prop="name">
              <template slot-scope="scope">
                <div
                  class="demo-image__preview"
                  style="display:flex;align-items:center"
                >
                  <el-image
                    style="width: 36px; height: 36px;margin-right:5px"
                    :src="scope.row.goodsSkuImg ? scope.row.goodsSkuImg : moren"
                    :preview-src-list="[
                      scope.row.goodsSkuImg ? scope.row.goodsSkuImg : moren,
                    ]"
                  />
                  <div>
                    <div>{{ scope.row.goodsName }}</div>
                    <div>规格：{{ scope.row.goodsSkuName }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="shippingQuantity"> </el-table-column>
            <el-table-column label="售后状态" >
            <template slot-scope="scope">
              {{scope.row.refundStatus == 0 ?'未申请':scope.row.refundStatus == 1 ?'进行中':scope.row.refundStatus ==2  ?'部分完成':scope.row.refundStatus ==  3 ?'申请完成':scope.row.refundStatus ==  4 ? '申请失败 ':''}}
            </template>  
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-form
        ref="forms"
        :model="form"
        :rules="rules"
        label-width="85px"
        size="mini"
      >
        <el-form-item label="发货类型" prop="shippingType">
          <el-select
            v-model="form.shippingType"
            filterable
            placeholder="请选择物流公司"
          >
            <el-option
              v-for="item in expressSelect"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="物流公司"
          prop="expressCompanyCode"
          v-if="form.shippingType === 1"
        >
          <el-select
            v-model="form.expressCompanyCode"
            filterable
            placeholder="请选择物流公司"
          >
            <el-option
              v-for="item in expressLists"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="物流单号"
          prop="expressNo"
          v-if="form.shippingType === 1"
        >
          <el-input
            v-model="form.expressNo"
            style="width: 30%;"
            maxlength="20"
            placeholder="请填写物流单号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <div style="display:flex;  justify-content: flex-end;">
            <el-button
              type="primary"
              :loading="loadings"
              @click="submitForm('forms')"
              >确定发货</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </el-drawer>
</template>

<script>
import { Message } from "element-ui";
import { shipping } from "@/api/supplierGoods/order";
export default {
  name: "Info",
  props: {
    loading:{
      type:Boolean,
      default:false
    },
    tableData: {
      type: Object,
      default: () => {},
    },
    expressLists: {
      type: Array,
    },
    orderDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      expressSelect: [
        {
          label: "无需物流",
          value: 0,
        },
        {
          label: "物流配送",
          value: 1,
        },
      ],
      loadings: false,
      activeName: "first",
      dialogVisible: false,
      options: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      form: {
        expressNo: "",
        expressCompanyCode: "",
      },
      isShow: false,
      checkList: [],
      rules: {
        expressCompanyCode: [
          { required: true, message: "请选择物流公司：", trigger: "change" },
        ],
        shippingType: [
          { required: true, message: "请选择发货类型：", trigger: "change" },
        ],
        expressNo: [
          { required: true, message: "请填写物流单号", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    selectable(row,index) {
      if (row.refundStatus == 0 || row.refundStatus == 4) {
        return true         //禁用
      } else {
        return false          //可选
      }
    },
    open() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.$refs['forms'].resetFields();
      this.loadings = false
      this.dialogVisible = false;
    },
    handleSelectionChange(val) {
      this.checkList = val;
    },
    // 订单发货
    async orderShoping(datas) {
      try {
        const res = await shipping(datas);
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.handleClose();
          this.$emit("updateData");
        }
      } catch (error) {
        this.loadings = false;
        this.$message.error(res.msg);
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.checkList.length && this.isShow) {
            Message.error("请选择需要发货商品");
            return false;
          }
          let list = this.isShow
            ? [...this.checkList]
            : [...this.orderDetails.noList];
          let data = {
            orderId: this.orderDetails.orderId,
            ...this.form,
            shippingItems: list.map((item) => {
              return { orderItemId: item.id, quantity: this.isShow ? item.quantityCopy:item.quantity };
            }),
          };
          this.loadings = true;
          this.isShow = false
          this.orderShoping(data);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 40px;
}
/deep/.el-form {
  margin-top: 30px;
}
.el-switch {
  margin-bottom: 30px;
}
</style>
